import logo from './logo.svg';
import './App.css';
import Home from './components/pages/home/Home';
import { BrowserRouter as  Router , Routes , Route } from 'react-router-dom';

function App() {
   console.log = console.info = console.warn = console.error = () => { };
  return (
    <>
     <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
