import React from 'react'
import Header from '../../header/Header'
import Banner from "../../images/top-banner.jpg"
import HeadingLine from "../../images/heading-line.svg"
import Shape from "../../images/shape.svg"
import Greentick from "../../images/greentick.svg"
import Image4 from "../../images/Image4@2x.png"
import Group3 from "../../images/Group3.svg"
import Group22 from "../../images/Group22.svg"
import Group893 from "../../images/Group893.svg"
import NewNo from "../../images/new-no.svg"
import SimCard from "../../images/sim-card.svg"
import ActiveAccount from "../../images/active-account.svg"

function Home() {
  return (
    <>

      <Header></Header>
      <main>
        <div className="top-bannerimg">
          <img src={Banner} className="img-fluid" />
          <button type="button" className="btn btn-buy">Buy Now</button>
        </div>

        <section className="customer-offer">
          <div className="container">
            <div className="row">
              <div className="heading">
                <h2>New Customer Offer</h2>
                <p>Our New Customer Offer lets you try us out for <br /> 3 Months at our lowest possible price.</p>
                <img src={HeadingLine} alt="icon" className="img-fluid wmanag-line" />
              </div>

              <div className="nav-tabs-wrapper">
                <ul className="nav nav-tabs dragscroll horizontal">
                  <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#tabA">1</a></li>
                  <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#tabB">2</a></li>
                  <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#tabC">3</a></li>
                  <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#tabD">4</a></li>
                </ul>
              </div>

              <div className="tab-content w-100">
                <div className="tab-pane fade show active" id="tabA">
                  <div className="row flex-align">
                    <div className="col-md-3">
                      <div className="data-box">
                        <p className="g5">5G • 4G LTE DATA</p>
                        <h4 className="gb-m">1GB<sub>/MO</sub></h4>
                        <div className="relative">
                          <img src={Shape} alt="icon" className="shape-gb" />
                          <span className="compare">
                            <a href="#">Compare Raza vs AT&T & Verizon</a></span>
                        </div>
                        <div className="dollar-text">
                          <sup>$</sup>5<sub>/MO</sub>
                        </div>
                        <div className="points-link">
                          <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                          <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                          <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                        </div>
                        <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                        <p className="servi-m">$45 for 3 months of service</p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="data-box">
                        <p className="g5">5G • 4G LTE DATA</p>
                        <h4 className="gb-m">2GB<sub>/MO</sub></h4>
                        <div className="relative">
                          <img src={Shape} alt="icon" className="shape-gb" />
                          <span className="compare">
                            <a href="#">Compare Raza vs AT&T & Verizon</a></span>
                        </div>
                        <div className="dollar-text">
                          <sup>$</sup>8<sub>/MO</sub>
                        </div>
                        <div className="points-link">
                          <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                          <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                          <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                        </div>
                        <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                        <p className="servi-m">$60 for 3 months of service</p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="data-box">
                        <p className="g5">5G • 4G LTE DATA</p>
                        <h4 className="gb-m">3GB<sub>/MO</sub></h4>
                        <div className="relative">
                          <img src={Shape} alt="icon" className="shape-gb" />
                          <span className="compare">
                            <a href="#">Compare Raza vs AT&T & Verizon</a></span>
                        </div>
                        <div className="dollar-text">
                          <sup>$</sup>10<sub>/MO</sub>
                        </div>
                        <div className="points-link">
                          <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                          <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                          <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                        </div>
                        <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                        <p className="servi-m">$60 for 3 months of service</p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="data-box">
                        <h4 className="gb-m font38">UNLIMITED</h4>
                        <div className="relative">
                          <img src={Shape} alt="icon" className="shape-gb" />
                          <span className="compare">
                            <a href="#">Compare Raza vs AT&T & Verizon</a></span>
                        </div>
                        <div className="dollar-text">
                          <sup>$</sup>30<sub>/MO</sub>
                        </div>
                        <div className="points-link">
                          <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                          <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                          <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                        </div>
                        <div className="bot">
                          <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                          <p className="servi-m">$60 for 3 months of service</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="tabB">
                  <div className="row justify-content-center">
                    <div className="col-md-3">
                      <div className="data-box">
                        <p className="g5">5G • 4G LTE DATA</p>
                        <h4 className="gb-m">1GB<sub>/MO</sub></h4>
                        <div className="relative">
                          <img src={Shape} alt="icon" className="shape-gb" />
                          <span className="compare">
                            <a href="#">Compare Raza vs AT&T & Verizon</a></span>
                        </div>
                        <div className="dollar-text">
                          <sup>$</sup>5<sub>/MO</sub>
                        </div>
                        <div className="points-link">
                          <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                          <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                          <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                        </div>
                        <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                        <p className="servi-m">$45 for 3 months of service</p>
                      </div>
                    </div>


                    <div className="col-md-3">
                      <div className="data-box">
                        <p className="g5">5G • 4G LTE DATA</p>
                        <h4 className="gb-m">3GB<sub>/MO</sub></h4>
                        <div className="relative">
                          <img src={Shape} alt="icon" className="shape-gb" />
                          <span className="compare">
                            <a href="#">Compare Raza vs AT&T & Verizon</a></span>
                        </div>
                        <div className="dollar-text">
                          <sup>$</sup>10<sub>/MO</sub>
                        </div>
                        <div className="points-link">
                          <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                          <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                          <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                        </div>
                        <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                        <p className="servi-m">$60 for 3 months of service</p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="data-box">
                        <h4 className="gb-m font38">UNLIMITED</h4>
                        <div className="relative">
                          <img src={Shape} alt="icon" className="shape-gb" />
                          <span className="compare">
                            <a href="#">Compare Raza vs AT&T & Verizon</a></span>
                        </div>
                        <div className="dollar-text">
                          <sup>$</sup>30<sub>/MO</sub>
                        </div>
                        <div className="points-link">
                          <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                          <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                          <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                        </div>
                        <div className="bot">
                          <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                          <p className="servi-m">$60 for 3 months of service</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="tabC">
                  <div className="row flex-align">
                    <div className="col-md-3">
                      <div className="data-box">
                        <p className="g5">5G • 4G LTE DATA</p>
                        <h4 className="gb-m">1GB<sub>/MO</sub></h4>
                        <div className="relative">
                          <img src={Shape} alt="icon" className="shape-gb" />
                          <span className="compare">
                            <a href="#">Compare Raza vs AT&T & Verizon</a></span>
                        </div>
                        <div className="dollar-text">
                          <sup>$</sup>5<sub>/MO</sub>
                        </div>
                        <div className="points-link">
                          <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                          <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                          <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                        </div>
                        <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                        <p className="servi-m">$45 for 3 months of service</p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="data-box">
                        <p className="g5">5G • 4G LTE DATA</p>
                        <h4 className="gb-m">2GB<sub>/MO</sub></h4>
                        <div className="relative">
                          <img src={Shape} alt="icon" className="shape-gb" />
                          <span className="compare">
                            <a href="#">Compare Raza vs AT&T & Verizon</a></span>
                        </div>
                        <div className="dollar-text">
                          <sup>$</sup>8<sub>/MO</sub>
                        </div>
                        <div className="points-link">
                          <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                          <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                          <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                        </div>
                        <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                        <p className="servi-m">$60 for 3 months of service</p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="data-box">
                        <p className="g5">5G • 4G LTE DATA</p>
                        <h4 className="gb-m">3GB<sub>/MO</sub></h4>
                        <div className="relative">
                          <img src={Shape} alt="icon" className="shape-gb" />
                          <span className="compare">
                            <a href="#">Compare Raza vs AT&T & Verizon</a></span>
                        </div>
                        <div className="dollar-text">
                          <sup>$</sup>10<sub>/MO</sub>
                        </div>
                        <div className="points-link">
                          <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                          <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                          <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                        </div>
                        <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                        <p className="servi-m">$60 for 3 months of service</p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="data-box">
                        <h4 className="gb-m font38">UNLIMITED</h4>
                        <div className="relative">
                          <img src={Shape} alt="icon" className="shape-gb" />
                          <span className="compare">
                            <a href="#">Compare Raza vs AT&T & Verizon</a></span>
                        </div>
                        <div className="dollar-text">
                          <sup>$</sup>30<sub>/MO</sub>
                        </div>
                        <div className="points-link">
                          <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                          <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                          <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                        </div>
                        <div className="bot">
                          <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                          <p className="servi-m">$60 for 3 months of service</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="tabD">
                  <div className="row justify-content-center">
                    <div className="col-md-3">
                      <div className="data-box">
                        <p className="g5">5G • 4G LTE DATA</p>
                        <h4 className="gb-m">1GB<sub>/MO</sub></h4>
                        <div className="relative">
                          <img src={Shape} alt="icon" className="shape-gb" />
                          <span className="compare">
                            <a href="#">Compare Raza vs AT&T & Verizon</a></span>
                        </div>
                        <div className="dollar-text">
                          <sup>$</sup>5<sub>/MO</sub>
                        </div>
                        <div className="points-link">
                          <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                          <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                          <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                        </div>
                        <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                        <p className="servi-m">$45 for 3 months of service</p>
                      </div>
                    </div>


                    <div className="col-md-3">
                      <div className="data-box">
                        <p className="g5">5G • 4G LTE DATA</p>
                        <h4 className="gb-m">3GB<sub>/MO</sub></h4>
                        <div className="relative">
                          <img src={Shape} alt="icon" className="shape-gb" />
                          <span className="compare">
                            <a href="#">Compare Raza vs AT&T & Verizon</a></span>
                        </div>
                        <div className="dollar-text">
                          <sup>$</sup>10<sub>/MO</sub>
                        </div>
                        <div className="points-link">
                          <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                          <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                          <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                        </div>
                        <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                        <p className="servi-m">$60 for 3 months of service</p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="data-box">
                        <h4 className="gb-m font38">UNLIMITED</h4>
                        <div className="relative">
                          <img src={Shape} alt="icon" className="shape-gb" />
                          <span className="compare">
                            <a href="#">Compare Raza vs AT&T & Verizon</a></span>
                        </div>
                        <div className="dollar-text">
                          <sup>$</sup>30<sub>/MO</sub>
                        </div>
                        <div className="points-link">
                          <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                          <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                          <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                        </div>
                        <div className="bot">
                          <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                          <p className="servi-m">$60 for 3 months of service</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="unlimited">Unlimited plan customers using 35GB/mo will experience lower speeds. Videos stream at 480p.
                <br /> Capable device required. Coverage not available in all areas.
                <i tabindex="0" role="button" data-toggle="popover" data-trigger="focus" title="Disclosure"
                  data-content="Unlimited on handset & network only. Hotspot capped at 5GB. Taxes and fees apply. https://www.raza.com/plan-terms-and-conditions/ "
                className="fas fa-question-circle popover-dismiss"></i>
               </p>
              <button type="button" className="btn framephone">
                <img src={Group893} alt="icon" />
                <span className="topm"> View All Plans</span>
              </button>
            </div>
          </div>
        </section>

        <section className="join-is-easy">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <img src={Image4} alt="images" className="img-fluid" />
              </div>
              <div className="col-md-7">
                <div className="joining">
                  <h2>Joining is Easy</h2>
                  <img src={Group3} alt="icon" className="img-fluid blacksvg" />
                  <p>Simply order your Raza SIM card here. We’ll send it to you by courier, pop it in your phone and you’re
                    good to Raza! Enjoy unlimited 4G speeds with 99% 4G covearge</p>
                </div>
                <div className="row  right-line">
                  <div className="col-md-3">
                    <img src={Group22} alt="icon" className="img-fluid" />
                    <h4>Select your SIM Only Plan </h4>
                  </div>
                  <div className="col-md-3">
                    <img src={SimCard} alt="icon" className="img-fluid" />
                    <h4>Order your SIM Card</h4>
                  </div>
                  <div className="col-md-3">
                    <img src={NewNo} alt="icon" className="img-fluid" />
                    <h4>Move or use your new number </h4>
                  </div>
                  <div className="col-md-3">
                    <img src={ActiveAccount} alt="icon" className="img-fluid" />
                    <h4>Activate your account</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>




      </main>
    </>
  )
}

export default Home